import React, { useEffect, useState } from 'react'
import { ClassTagSection } from 'components/ClassTagSection'
import { Class, ScheduledClass } from 'types/shopScheduleTypes'
import { IndividualClassTile } from 'components/IndividualClassTile'
import useActiveTags from 'hooks/useActiveTag'
import { ClassAdditionalDetails } from 'components/AdditionalDetails'
import { IndividualSelectionFooter } from 'components/IndividualSelectionFooter'
import useSelectedClasses from 'hooks/useSelectedClasses'
import AnimatedHeightDiv from 'components/AnimatedHeightDiv/AnimatedHeightDiv'
import { useNavigate } from 'react-router-dom'
import { SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH } from 'views/ScheduleIndividualClasses/utils/constants'
import { ThemedModal } from 'components/ThemedModal'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import useFetchClasses from './hooks/useFetchClasses'
import useSelectedBundle from 'hooks/useSelectedBundle'
import { useQuery } from '@apollo/client'
import { getAttributeMeta } from 'graphql/queries/attributeMeta'
import { IneligibleBlock } from 'components/IneligibleBlock'
import useScheduledClasses from 'hooks/useScheduledClasses'
import useDedupePreReg from './hooks/useDedupePreReg'


function IndividualSelection(): JSX.Element {
  const [ zoomedClass, setZoomedClass ] = useState<Class | null>( null )
  const [ openGotItModal, setOpenGotItModal ] = useState<boolean>( false )

  const { activeTag, clearActiveTag } = useActiveTags()
  const navigate = useNavigate()
  const { selectedClasses, isClassSelected, addClasses, removeClasses } = useSelectedClasses()

  const { selectedBundle, clearBundle } = useSelectedBundle()

  const { classes, error } = useFetchClasses()
  const { completedClasses, scheduledClasses } = useScheduledClasses()
  const { data: attributeMetaData, loading, error: filterMappingError } = useQuery( getAttributeMeta, {
    variables: {
      'attribute_code': `lactation_class_filters`,
      'entity_type': `catalog_product`
    }
  })

  const dedupedSelectedClasses = useDedupePreReg( selectedClasses )
  const dedupedClasses = useDedupePreReg( classes, scheduledClasses )


  useEffect( () => {
    sessionStorage.removeItem( `has_scheduled` )
    if ( selectedBundle ) clearBundle()
  }, [] )

  useEffect( () => {
    // auto select functionality when given a class id, class sku, or array of class skus
    if ( classes && classes.length ) {
      const searchParams = new URLSearchParams( window.location.search )
      const classId = searchParams.get( `class_id` )
      const classSku = searchParams.get( `sku` )
      const classSkus = searchParams.get( `skus` )

      if ( classId || classSku ) {
        // look for if class id or class sku matches a query param
        const classDetails = classes?.find( ( classItem: Class ) => {
          return classItem.class_id === classId || classItem.class_sku === classSku
        })

        if ( classDetails ) {
          handleSelectClass( classDetails )
          const updatedURLSearchParams = new URLSearchParams( window.location.search )
          updatedURLSearchParams.delete( `class_id` )
          updatedURLSearchParams.delete( `sku` )

          return navigate( `${SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH}?${updatedURLSearchParams.toString()}` )
        }
      }

      if ( classSkus ) {
        const classSkusArray = classSkus.split( `,` )

        classSkusArray.forEach( ( sku: string ) => {
          const classDetails = classes?.find( ( classItem: Class ) => {
            return classItem.class_sku === sku
          })

          if ( classDetails ) handleSelectClass( classDetails )
        })
        const updatedURLSearchParams = new URLSearchParams( window.location.search )
        updatedURLSearchParams.delete( `skus` )

        return navigate( `${SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH}?${updatedURLSearchParams.toString()}` )
      }

    }
  }, [ classes ] )

  const openModal = ( selectedClass: Class ) => {
    setZoomedClass( selectedClass )
  }

  const handleClose = () => {
    setZoomedClass( null )
  }

  const closeGotIt = () => {
    setOpenGotItModal( false )
  }

  const handleModalSelect = () => {
    if ( zoomedClass ) handleSelectClass( zoomedClass )
    handleClose()
  }

  const scheduleClasses = () => {
    // only want selected bundle or selected classes
    clearBundle()
    clearActiveTag()

    return navigate( SCHEDULE_INDIVIDUAL_CLASSES_BASE_PATH.concat( window.location.search ) )
  }

  const getSeriesClasses = ( classDetails: Class ): Class[] => {
    // allow patients to retake individual classes in a series if they have completed it already
    const currentClassCompleted = completedClasses?.find( ( c: ScheduledClass ) => {
      return c.class_id === classDetails.class_id
    })
    if ( !classDetails.is_series || currentClassCompleted ) return []

    return classes?.filter( ( classItem: Class ) => {
      // if a previous class in the series has been completed we don't want to force the patient to retake it
      const classInCompleted = completedClasses?.find( ( c: ScheduledClass ) => {
        return c.class_id === classItem.class_id
      })

      return classItem.is_series && classItem.class_series_pk === classDetails.class_series_pk && classItem.class_id !== classDetails.class_id && !classInCompleted
    }) ?? []
  }

  const handleSelectClass = ( classDetails: Class ) => {
    const allClasses: Class[] = getSeriesClasses( classDetails )

    allClasses.push( classDetails )
    // when selecting a class we need to go through the full classes array and make sure we add both legacy and pre-reg if their are both
    const allPreRegAndLegacyClasses = [ ...allClasses ]
    allClasses.forEach( ( classItem: Class ) => {
      const classesWithoutCurrent = [ ...( classes ?? [] ) ]
      const currentClassIndex = classesWithoutCurrent.findIndex( ( c: Class ) => {
        return c.class_id === classItem.class_id
      })
      classesWithoutCurrent.splice( currentClassIndex, 1 )
      const sameClass = classesWithoutCurrent.find( ( c: Class ) => {

        return c.internal_class_title.trim() === classItem.internal_class_title.trim()
      })

      if ( sameClass && ( ( classItem.is_series && allPreRegAndLegacyClasses.every( ( pAndLClass: Class ) => pAndLClass.class_id !== classItem.class_id ) || !classItem.is_series ) ) ) allPreRegAndLegacyClasses.push( Object.assign({}, sameClass ) )

    })

    allPreRegAndLegacyClasses.sort( ( a: Class, b: Class ) => {
      return a?.class_sequence < b?.class_sequence ? -1 : 1
    })

    if ( isClassSelected( classDetails ) ) return removeClasses( allPreRegAndLegacyClasses )

    const dedupedSelected = dedupedSelectedClasses ?? []
    if ( dedupedSelected.length === 5 || dedupedSelected.length + allClasses.length <= 6 ) return addClasses( allPreRegAndLegacyClasses )

    setOpenGotItModal( true )
  }

  const handleCardClick = ( e: React.MouseEvent<HTMLElement>, classDetails: Class ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const target = e.target as any
    if ( target?.alt !== `select class check` ) return openModal( classDetails )
  }

  if ( error ) return <SchedulingErrorBlock message={error} />

  if ( !classes || loading ) return <AppointmentsLoadingBox
    message="Please hold tight while we grab your available classes..."
  />

  if ( !classes?.length && !activeTag ) return <IneligibleBlock />

  return (
    <div>
      <div className="flex justify-center items-center flex-col my-10 px-5 text-center">
        <p className="text-3xl md:text-4xl font-header mt-5 mb-2">{`Select Your Classes`}</p>
        <p className="md:text-xl">{`Choose up to 6 live virtual classes and proceed to the schedule page`}</p>
        <ThemedModal
          message="Your maximum course availability is 6 classes at this time"
          open={openGotItModal}
          handleClose={closeGotIt}
        />
        <ClassAdditionalDetails
          classDetails={zoomedClass}
          handleClose={handleClose}
          addClass={handleModalSelect}
        />
        {
          attributeMetaData?.customAttributeMetadata?.items?.length && attributeMetaData?.customAttributeMetadata?.items[0]?.attribute_options && !filterMappingError &&
          <ClassTagSection
            classes={classes}
            filterMapping={attributeMetaData.customAttributeMetadata.items[0].attribute_options}
          />
        }
        <AnimatedHeightDiv
          display
          heightDependencies={[ classes ]}
          outerContainerClassName="w-full"
          innerContainerClassName="w-full flex items-center justify-center mx-auto"
          padHeight={100}
        >
          <div
            className="grid grid-cols-2 xl:grid-cols-3 gap-3 md:gap-10 md:px-10 justify-center items-center max-w-4xl md:my-5 min-w-fit w-full"
          >
            {
              dedupedClasses &&
              dedupedClasses?.filter( ( classDetails: Class ) => {
                return Boolean( classDetails.class_sku )
              }).map( ( classDetails: Class ) => {
                return (
                  <IndividualClassTile
                    key={classDetails.class_id}
                    classDetails={classDetails}
                    openModal={( e: React.MouseEvent<HTMLElement> ) => {
                      handleCardClick( e, classDetails )
                    }}
                    isSelected={isClassSelected( classDetails )}
                    handleSelectClass={handleSelectClass}
                  />
                )
              })
            }
          </div>
        </AnimatedHeightDiv>
      </div>
      {
        selectedClasses.length > 0 &&
        <IndividualSelectionFooter onClick={scheduleClasses} />
      }
    </div>
  )
}

export default IndividualSelection